import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { getImageUrl } from './utils/imageUtils';
import { sortEventsByDate } from './utils/eventUtils';
import './EventPage.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import MetaTags, { getEventMetaData } from './components/MetaTags';

// Helper function to format dates in a consistent way
const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

// Extracted reusable event content component
export const EventContent = ({ event, isFullPage = false, pressItems = [] }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const eventSlug = event.title.toLowerCase().replace(/[^a-z0-9]+/g, '-');

  // Sort press items by date (newest first)
  const sortedPressItems = [...pressItems].sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  useEffect(() => {
    const loadImages = async () => {
      if (event.preview_images) {
        const urls = await Promise.all(
          event.preview_images.map(async (imageData) => {
            const url = await getImageUrl(imageData.path);
            return { url, caption: imageData.caption };
          })
        );
        setImageUrls(urls);
      }
    };
    loadImages();
  }, [event.preview_images]);

  return (
    <div className={`event-content ${isFullPage ? 'full-page' : ''}`}>
      <div className="event-text-content">
        <div className="event-metadata">
          <div className="event-date-location">
            <span className="event-date">{event.date}</span>
            <span className="event-location">@ {event.location}</span>
            {event.time && <span className="event-time">// {event.time}</span>}
          </div>
          {isFullPage ? (
            <h1 className="event-title">{event.title}</h1>
          ) : (
            <Link to={`/events/${eventSlug}`} className="event-title">{event.title}</Link>
          )}
          {/* {(event.garments?.length > 0 || event.season) && (
            <div className="event-details">
              {event.season && <span className="event-season">{event.season}</span>}
              {event.garments?.length > 0 && (
                <div className="event-garments">
                  {event.garments.map((garment, index) => (
                    <span key={index} className="garment-tag">
                      {garment}
                    </span>
                  ))}
                </div>
              )}
            </div>
          )} */}
        </div>

        <div className="event-description">
          {event.description}
        </div>

        {event.links && event.links.length > 0 && event.links.map((link, index) => (
          <a
            key={index}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="event-link"
          >
            read more on {new URL(link).hostname.replace('www.', '')} {index > 0 ? `(${index + 1})` : ''} <span className="link-icon">↗</span>
          </a>
        ))}
      </div>

      {imageUrls.length > 0 && (
        <div className="event-gallery">
          <Swiper
            modules={[Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            grabCursor={imageUrls.length > 1}
            loop={imageUrls.length > 1}
            className="event-swiper"
            observer={true}
            observeParents={true}
            watchOverflow={true}
            autoHeight={true}
          >
            {imageUrls.map((imageData, index) => (
              <SwiperSlide key={index}>
                <div className="image-container">
                  <img
                    src={imageData.url}
                    alt={`${event.title} at ${event.location} - ${imageData.caption || `Image ${index + 1}`}`}
                    loading="lazy"
                    draggable="false"
                  />
                  {imageData.caption && (
                    <div className="image-caption">
                      {imageData.caption}
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      {sortedPressItems && sortedPressItems.length > 0 && (
        <div className="event-press-mentions">
          <h3 className="press-mentions-title">Press Coverage</h3>
          <div className="press-mentions-list">
            {sortedPressItems.map((pressItem, index) => (
              pressItem.links && pressItem.links.length > 0 ? (
                <a
                  key={index}
                  href={pressItem.links[0]}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="event-press-item"
                >
                  <span className="press-item-date">{formatDate(pressItem.date)}</span>
                  <span className="press-item-source">{pressItem.source}</span>
                  <span className="press-item-title">{pressItem.title}</span>
                </a>
              ) : (
                <div key={index} className="event-press-item">
                  <span className="press-item-date">{formatDate(pressItem.date)}</span>
                  <span className="press-item-source">{pressItem.source}</span>
                  <span className="press-item-title">{pressItem.title}</span>
                </div>
              )
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const EventNavigation = ({ event, allEvents }) => {
  const [prevImageUrl, setPrevImageUrl] = useState(null);
  const [nextImageUrl, setNextImageUrl] = useState(null);

  // Sort events by date, newest first
  const sortedEvents = sortEventsByDate(allEvents);
  const currentIndex = sortedEvents.findIndex(e => e.title === event.title);

  // Find next and previous events (newer = prev, older = next)
  const prevEvent = currentIndex > 0 ? sortedEvents[currentIndex - 1] : null;
  const nextEvent = currentIndex < sortedEvents.length - 1 ? sortedEvents[currentIndex + 1] : null;

  useEffect(() => {
    const loadPreviewImages = async () => {
      if (prevEvent?.preview_images?.[0]) {
        const url = await getImageUrl(prevEvent.preview_images[0].path);
        setPrevImageUrl(url);
      }
      if (nextEvent?.preview_images?.[0]) {
        const url = await getImageUrl(nextEvent.preview_images[0].path);
        setNextImageUrl(url);
      }
    };
    loadPreviewImages();
  }, [prevEvent, nextEvent]);

  const getEventSlug = (event) => event.title.toLowerCase().replace(/[^a-z0-9]+/g, '-');

  return (
    <div className="event-navigation">
      {prevEvent && (
        <Link to={`/events/${getEventSlug(prevEvent)}`} className="event-nav-link prev">
          {prevImageUrl && (
            <div className="nav-image">
              <img src={prevImageUrl} alt={prevEvent.title} loading="lazy" />
            </div>
          )}
          <div className="nav-content">
            <span className="nav-label">prev</span>
            <span className="nav-title">{prevEvent.title}</span>
            <span className="nav-date">{prevEvent.date}</span>
          </div>
        </Link>
      )}
      {nextEvent && (
        <Link to={`/events/${getEventSlug(nextEvent)}`} className="event-nav-link next">
          {nextImageUrl && (
            <div className="nav-image">
              <img src={nextImageUrl} alt={nextEvent.title} loading="lazy" />
            </div>
          )}
          <div className="nav-content">
            <span className="nav-label">next</span>
            <span className="nav-title">{nextEvent.title}</span>
            <span className="nav-date">{nextEvent.date}</span>
          </div>
        </Link>
      )}
    </div>
  );
};

const EventItem = ({ event, pressItems = [] }) => (
  <EventContent event={event} isFullPage={false} pressItems={pressItems} />
);

const EventPage = ({ data }) => {
  const { eventSlug } = useParams();
  const navigate = useNavigate();

  const event = data?.events?.find(e =>
    e.title.toLowerCase().replace(/[^a-z0-9]+/g, '-') === eventSlug
  );

  // Find press mentions related to this event
  const relatedPressItems = React.useMemo(() => {
    if (!event || !data?.press_mentions) return [];

    // Only show press items that are explicitly linked through press_refs
    if (event.press_refs && event.press_refs.length > 0) {
      return data.press_mentions.filter(press =>
        event.press_refs.includes(press.id) && press.id
      );
    }

    return [];
  }, [event, data?.press_mentions]);

  useEffect(() => {
    if (!event) {
      navigate('/about');
    }
  }, [event, navigate]);

  if (!event) return null;

  return (
    <>
      <MetaTags {...getEventMetaData(event)} />
      <div className="event-page">
        <EventContent event={event} isFullPage={true} pressItems={relatedPressItems} />
        <EventNavigation event={event} allEvents={data.events} />
      </div>
    </>
  );
};

export default EventPage;