import React, { useState, useEffect, useRef } from 'react';
import { getImageUrl } from './utils/imageUtils';
import './AboutPage.scss';
import { Link } from 'react-router-dom';
import { getProjectByAirtableId } from './utils/projectUtils';
import BrevoForm from './components/BrevoForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EventContent } from './EventPage';
import MetaTags, { getAboutMetaData } from './components/MetaTags';

const PressItem = ({ item, formatDate }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      if (item.preview_image) {
        const url = await getImageUrl(item.preview_image.path);
        setImageUrl(url);
      }
    };
    loadImage();
  }, [item.preview_image]);

  const content = (
    <div className="press-content">
      <div className="press-header">
        <div className="press-metadata">
          <span className="press-date">{formatDate(item.date)}</span>
          <span className="press-title">{item.title}</span>
        </div>
      </div>

      {item.links && item.links.length > 0 && (
        <div className="press-links">
          {item.links.map((link, index) => (
            <a
              key={index}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="press-cta"
            >
              see at {item.source || item.magazine} {index > 0 ? `(${index + 1})` : ''} <span className="link-icon">↗</span>
            </a>
          ))}
        </div>
      )}
    </div>
  );

  const pressItem = (
    <>
      {imageUrl && (
        <div className="press-gallery">
          <div className="image-container">
            <img
              src={imageUrl}
              alt={`${item.title} - Press coverage in ${item.source || item.magazine}`}
              loading="lazy"
              draggable="false"
            />
            <div className="image-caption">
              {item.source || item.magazine}
            </div>
          </div>
        </div>
      )}
      {content}
    </>
  );

  if (!item.links || item.links.length === 0) {
    return <div className="press-item">{pressItem}</div>;
  }

  // If there's only one link, wrap the entire item in a link
  if (item.links.length === 1) {
    return (
      <a
        href={item.links[0]}
        target="_blank"
        rel="noopener noreferrer"
        className="press-item"
      >
        {pressItem}
      </a>
    );
  }

  // If there are multiple links, just show the item with individual link buttons
  return <div className="press-item">{pressItem}</div>;
};

const MuseCard = ({ muse }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      if (muse.image) {
        const url = await getImageUrl(muse.image);
        setImageUrl(url);
      }
    };
    loadImage();
  }, [muse.image]);

  return (
    <div className="muse-card">
      <div className="muse-image">
        {imageUrl && (
          <img
            src={imageUrl}
            alt={`${muse.name} - ${muse.profession?.join(', ')}`}
            loading="lazy"
          />
        )}
      </div>
      <div className="muse-content">
        <div className="muse-header">
          <span className="muse-name">{muse.name}</span>
          <span className="muse-role">{muse.profession?.join(', ')}</span>
        </div>
        <p className="muse-description">{muse.bio}</p>
        {muse.link && (
          <a href={muse.link} target="_blank" rel="noopener noreferrer" className="muse-instagram">
            [Instagram ↗]
          </a>
        )}
      </div>
    </div>
  );
};

const sortByName = (a, b) => a.name.localeCompare(b.name);

const renderProjectLinks = (collections) => {
  if (!collections || collections.length === 0) return null;

  return collections.map((collection, index) => {
    const project = getProjectByAirtableId(collection);
    if (!project) return null;

    return (
      <span key={collection}>
        {index > 0 && ', '}
        <Link to={`/gallery/${project.id}`} className="project-link">
          {project.name}
        </Link>
      </span>
    );
  }).filter(Boolean);
};

const renderLink = (link, name, info) => {
  const content = (
    <div className="info-line">
      <span className="info-key">{name}</span>
      <span className="info-value">{info}</span>
    </div>
  );

  if (link) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className="external-link">
        {content}
      </a>
    );
  }
  return content;
};

const AboutPage = ({ data }) => {
  const [email, setEmail] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    about: true,
    events: true,
    awards: true,
    press: true,
    muses: true,
    collaborators: true,
    contact: true
  });

  useEffect(() => {
    const loadBackgroundImage = async () => {
      if (data?.backgroundImage) {
        const url = await getImageUrl(data.backgroundImage);
        setBackgroundImageUrl(url);
      }
    };
    loadBackgroundImage();
  }, [data?.backgroundImage]);

  const handleFormSubmit = (success) => {
    setChatHistory(prev => [...prev, { type: 'command', content: email }]);

    if (success) {
      setChatHistory(prev => [...prev,
      { type: 'response', content: '>> ACCESS GRANTED :: WELCOME TO THE NETWORK <<' }
      ]);
    } else {
      setChatHistory(prev => [...prev,
      { type: 'error', content: '>> ERROR :: CONNECTION FAILED <<' }
      ]);
    }
  };

  // Early return if no data is provided
  if (!data) {
    return <div>Loading...</div>;
  }

  const toggleSection = (section) => {
    setExpandedSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(dateString);
    if (isNaN(date)) {
      // Handle custom date formats like "September 12—15, 2024"
      return dateString.replace(/(January|February|March|April|May|June|July|August|September|October|November|December)/g,
        (match) => match.substring(0, 3));
    }
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const renderSection = (title, content) => (
    <section className="about-section">
      <h2 className="section-header" onClick={() => toggleSection(title.toLowerCase())}>
        <span className={`chevron ${expandedSections[title.toLowerCase()] ? 'expanded' : ''}`}>›</span>
        {title}
      </h2>
      <div className={`section-content ${expandedSections[title.toLowerCase()] ? 'expanded' : ''}`}>
        {content}
      </div>
    </section>
  );

  return (
    <>
      <MetaTags {...getAboutMetaData()} />
      <div
        className="about-page"
        style={{
          backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none',
          '--project-theme-color': data.themeColor || '#091115'
        }}
      >
        <div className="about-content">
          <div className="about-frame">
            <h1>{'// About Elodie Carstensen'}</h1>
            {renderSection("ABOUT", (
              <div className="about-info">
                {data.imprint && (
                  <>
                    <div className="info-line">
                      <span className="info-key">creator</span>
                      <span className="info-value">{data.imprint.name}</span>
                    </div>
                    <div className="info-line">
                      <span className="info-key">location</span>
                      <span className="info-value">{data.imprint.address?.join(', ')}</span>
                    </div>
                  </>
                )}
                {data.inquiries && (
                  <div className="info-line">
                    <span className="info-key">email</span>
                    <span className="info-value">
                      <a href={`mailto:${data.inquiries.email}`} className="email-link">
                        {data.inquiries.email}
                        <svg className="mail-icon" width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.6 0H1.4C0.63 0 0.00699999 0.63 0.00699999 1.4L0 9.8C0 10.57 0.63 11.2 1.4 11.2H12.6C13.37 11.2 14 10.57 14 9.8V1.4C14 0.63 13.37 0 12.6 0ZM12.6 2.8L7 6.3L1.4 2.8V1.4L7 4.9L12.6 1.4V2.8Z" fill="white" />
                        </svg>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            ))}
            {data.events?.length > 0 && renderSection("EVENTS", (
              <div className="events-list">
                {Object.entries(data.events.reduce((acc, event) => {
                  const year = new Date(event.date).getFullYear();
                  if (!acc[year]) acc[year] = [];
                  acc[year].push(event);
                  return acc;
                }, {})).sort(([yearA], [yearB]) => Number(yearB) - Number(yearA)).map(([year, eventsForYear]) => (
                  <div key={`year-group-${year}`} className="year-content">
                    <h3 className="year"><span>{year}</span></h3>
                    {eventsForYear.map((event, eventIndex) => (
                      <React.Fragment key={`event-${year}-${eventIndex}`}>
                        {eventIndex > 0 && <div className="event-divider"></div>}
                        <EventContent
                          event={event}
                          isFullPage={false}
                          pressItems={data.press_mentions?.filter(press => {
                            // Only show press items that are explicitly linked through press_refs
                            if (event.press_refs && event.press_refs.length > 0) {
                              return event.press_refs.includes(press.id) && press.id;
                            }
                            return false;
                          })}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                ))}
              </div>
            ))}
            {data.awards?.length > 0 && renderSection("Awards", (
              <ul className="awards-list">
                {data.awards.map((award, index) => (
                  <li key={index} className="info-line">
                    {renderLink(award.link, award.title, award.type + ', ' + award.year)}
                    <div className="info-value">
                      {award.description}
                    </div>
                  </li>
                ))}
              </ul>
            ))}
            {data.press_mentions?.length > 0 && renderSection("Press", (
              <div className="press-list">
                {data.press_mentions.map((item, index) => (
                  <PressItem
                    key={index}
                    item={item}
                    formatDate={formatDate}
                  />
                ))}
              </div>
            ))}
            {data.muses?.length > 0 && renderSection("Muses", (
              <div className="muses-grid">
                {data.muses
                  .filter(muse => !muse.hide)
                  .map((muse, index) => (
                    <MuseCard
                      key={index}
                      muse={muse}
                    />
                  ))}
              </div>
            ))}
            {data.collaborators?.length > 0 && renderSection("Collaborators", (
              <div className="person-list">
                {data.collaborators.sort(sortByName).map((person, index) => (
                  <div key={index} className="info-line">
                    <span className="info-key">{person.name}</span>
                    <span className="info-value">
                      <span>{person.profession?.join(', ')}</span>
                      {person.collection && (
                        <span className="project-links">
                          {' ['}{renderProjectLinks(person.collection)}{']'}
                        </span>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
            <div className="newsletter-form top-form">
              <h2>[SYSTEM::ACCESS] End of preview reached</h2>
              <div className="chat-history">
                {chatHistory.map((entry, index) => (
                  <div key={index} className={`chat-entry ${entry.type}`}>
                    {entry.type === 'command' ? `> ${entry.content}` : entry.content}
                  </div>
                ))}
              </div>
              <BrevoForm
                onSubmit={handleFormSubmit}
                email={email}
                setEmail={setEmail}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
