export const getAssetUrl = (path) => `${process.env.PUBLIC_URL}/assets/images/${path}`;

// Create a map to store preloaded images
const preloadedImages = new Map();

export const preloadImage = (url) => {
  if (preloadedImages.has(url)) {
    return preloadedImages.get(url);
  }

  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(url);
    img.onerror = reject;
    img.src = url;
  });

  preloadedImages.set(url, promise);
  return promise;
};

export const images = {
  splash: {
    full: getAssetUrl('splash.jpg'),
    lowRes: getAssetUrl('splash-lowres.jpg'),
  },
  logo: getAssetUrl('logo.svg'),
  projects: {
    absenceOfPromisedSafety: {
      faunus: getAssetUrl('absence-of-promised-safety/01_FAUNUS.jpg'),
      gina: getAssetUrl('absence-of-promised-safety/02_GINA.jpg'),
      eden: getAssetUrl('absence-of-promised-safety/03_EDEN.jpg'),
      mitra: getAssetUrl('absence-of-promised-safety/04_MITRA.jpg'),
      grana: getAssetUrl('absence-of-promised-safety/05_GRANA.jpg'),
      neo: getAssetUrl('absence-of-promised-safety/06_NEO.jpg'),
      limax: getAssetUrl('absence-of-promised-safety/08_LIMAX.jpg'),
      taia: getAssetUrl('absence-of-promised-safety/09_TAIA.jpg'),
      helix: getAssetUrl('absence-of-promised-safety/10_HELIX.jpg'),
      // ... add other images here
    },
    // ... other projects
  }
};
