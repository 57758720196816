// Helper function to group events by year
export const groupEventsByYear = (events) => {
  return events.reduce((acc, event) => {
    const year = new Date(event.date).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(event);
    return acc;
  }, {});
};

// Helper function to sort events by date
export const sortEventsByDate = (events) => {
  if (!events) return [];

  return [...events].sort((a, b) => {
    // Handle custom date formats like "September 12—15, 2024"
    const getDateFromString = (dateStr) => {
      // If it's a range, take the first date
      const firstDate = dateStr.split('—')[0];
      // Try to parse the date
      const date = new Date(firstDate);
      return isNaN(date) ? new Date(0) : date;
    };

    const dateA = getDateFromString(a.date);
    const dateB = getDateFromString(b.date);

    return dateB - dateA; // Sort in descending order (newest first)
  });
};