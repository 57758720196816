import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import { routes } from '../utils/routingConfig';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    // If we're in an admin route, redirect to the main login
    if (location.pathname.startsWith('/admin')) {
      return <Navigate to={routes.login} replace state={{ from: location }} />;
    }
    // Otherwise, redirect to rental login
    return <Navigate to="/rental/login" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;