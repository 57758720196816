import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAssetUrl, images, preloadImage } from './assetUtils';
import enterCursor from './assets/enter.svg';
import './SplashPage.scss';
import MetaTags, { defaultMetaData } from './components/MetaTags';

const SplashPage = () => {
  const logoUrl = images.logo;
  const [isHighResLoaded, setIsHighResLoaded] = useState(false);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  // Preload high-res image
  useEffect(() => {
    preloadImage(images.splash.full)
      .then(() => setIsHighResLoaded(true))
      .catch(console.error);
  }, []);

  const handleContainerClick = () => {
    navigate('/gallery');
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    // Update these percentages to match the visual snap points
    const snapPoints = [19, 27, 36.5, 41, 50, 59, 64.5, 80].map(
      percentage => (percentage / 100) * scrollContainer.scrollWidth
    );

    // Scroll to the first snap point on load
    const scrollToFirstSnapPoint = () => {
      const firstSnapPoint = snapPoints[0];
      const targetScrollLeft = Math.max(0, firstSnapPoint - 100);
      scrollContainer.scrollLeft = targetScrollLeft;
    };

    setTimeout(scrollToFirstSnapPoint, 100);

    let isScrolling = false;
    let scrollTimeout;
    let isDragging = false;
    let startX, startScrollLeft;
    let isSnapping = false;

    const getClosestSnapPoint = (scrollPosition) => {
      const containerCenter = scrollPosition + scrollContainer.clientWidth / 2;
      return snapPoints.reduce((prev, curr) =>
        Math.abs(curr - containerCenter) < Math.abs(prev - containerCenter) ? curr : prev
      );
    };

    const smoothScrollTo = (target, duration) => {
      const start = scrollContainer.scrollLeft;
      const distance = target - start;
      let startTime = null;

      const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const ease = t => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        scrollContainer.scrollLeft = start + distance * ease(progress);

        if (timeElapsed < duration) {
          requestAnimationFrame(animation);
        } else {
          isSnapping = false;
        }
      };

      requestAnimationFrame(animation);
    };

    const handleScroll = () => {
      if (isScrolling) {
        clearTimeout(scrollTimeout);
      }
      isScrolling = true;

      scrollTimeout = setTimeout(() => {
        isScrolling = false;
        if (!isDragging && !isSnapping) {
          const closestSnapPoint = getClosestSnapPoint(scrollContainer.scrollLeft);
          const currentPosition = scrollContainer.scrollLeft;
          const targetPosition = closestSnapPoint - scrollContainer.clientWidth / 2;
          const distance = targetPosition - currentPosition;

          if (Math.abs(distance) > 10) {
            isSnapping = true;
            smoothScrollTo(targetPosition, 300);
          }
        }
      }, 100);
    };

    const handleMouseDown = (e) => {
      isDragging = true;
      startX = e.pageX - scrollContainer.offsetLeft;
      startScrollLeft = scrollContainer.scrollLeft;
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - scrollContainer.offsetLeft;
      const walk = (x - startX) * 2;
      scrollContainer.scrollLeft = startScrollLeft - walk;
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    scrollContainer.addEventListener('scroll', handleScroll, { passive: true });
    scrollContainer.addEventListener('mousedown', handleMouseDown);
    scrollContainer.addEventListener('mousemove', handleMouseMove);
    scrollContainer.addEventListener('mouseup', handleMouseUp);
    scrollContainer.addEventListener('mouseleave', handleMouseUp);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      scrollContainer.removeEventListener('mousedown', handleMouseDown);
      scrollContainer.removeEventListener('mousemove', handleMouseMove);
      scrollContainer.removeEventListener('mouseup', handleMouseUp);
      scrollContainer.removeEventListener('mouseleave', handleMouseUp);
      clearTimeout(scrollTimeout);
    };
  }, []);

  return (
    <>
      <MetaTags {...defaultMetaData} />
      <div
        className="splash-container"
        style={{ '--enter-cursor': `url(${enterCursor})` }}
      >
        {/* Hidden content for screen readers and search engines */}
        <div className="visually-hidden">
          <h1>Elodie Carstensen</h1>
          <p>
            Elodie Carstensen is a Berlin-based designer and artist, crafting immersive, story-driven fashion through collections like Alluvial, Des Nachtmahrs Schmetterlinge, and Absence of Promised Safety. Known for custom costume design and thematic depth, her work spans installations, performances, and collaborative projects.
          </p>
        </div>

        <div className="splash-glass-effect" aria-hidden="true" />
        <div className="splash-content" aria-hidden="true">
          <div className="splash-logo-name">
            <img
              src={logoUrl}
              alt="Elodie Carstensen"
              className="splash-logo"
              loading="eager"
              width="200"
              height="50" // adjust dimensions as needed
            />
            <h1 className="splash-title">ELODIE CARSTENSEN</h1>
          </div>
          <div className="splash-text-container">
            <p className="splash-intro-text">
              Elodie Carstensen is a Berlin-based artist and designer. She is creating worlds that are reveled in the webs of imagination and analysis, experimenting with fashion, immersive installations, custom costume designs, performances and collaborations. Her collections Alluvial, Des Nachtmahrs Schmetterlinge, and Absence of Promised Safety are the beginning of her ever expanding universe.</p>
            <button className="splash-enter-button" onClick={handleContainerClick}>
              ENTER <span className="slash-icon">/</span>
            </button>
          </div>
        </div>
        <div
          className="splash-scroll-container"
          ref={scrollContainerRef}
          onClick={handleContainerClick}
        >
          <div className="splash-background"
            style={{
              backgroundImage: `url(${isHighResLoaded ? images.splash.full : images.splash.lowRes})`,
              transition: 'background-image 0.5s ease-in'
            }}
            aria-hidden="true"
          >
            <div className="snap-points">
              <div className="snap-point" style={{ left: '19%' }}></div>
              <div className="snap-point" style={{ left: '27%' }}></div>
              <div className="snap-point" style={{ left: '36.5%' }}></div>
              <div className="snap-point" style={{ left: '41%' }}></div>
              <div className="snap-point" style={{ left: '50%' }}></div>
              <div className="snap-point" style={{ left: '59%' }}></div>
              <div className="snap-point" style={{ left: '64.5%' }}></div>
              <div className="snap-point" style={{ left: '80%' }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SplashPage;
