import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check localStorage on initial load
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  const login = (username, password) => {
    const correctUsername = process.env.REACT_APP_ADMIN_USERNAME;
    const correctPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    console.log('Login attempt:', {
      providedUsername: username,
      correctUsername,
      hasPassword: !!password,
      envVarsSet: {
        username: !!process.env.REACT_APP_ADMIN_USERNAME,
        password: !!process.env.REACT_APP_ADMIN_PASSWORD
      }
    });

    if (username === correctUsername && password === correctPassword) {
      setIsAuthenticated(true);
      // Save authentication state to localStorage
      localStorage.setItem('isAuthenticated', 'true');
      return true;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
    // Clear authentication state from localStorage
    localStorage.removeItem('isAuthenticated');
  };

  // Provide the authentication context
  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};