import React from 'react';
import './Loading.scss';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-text">
        LOADING
        <span className="dot-1">.</span>
        <span className="dot-2">.</span>
        <span className="dot-3">.</span>
      </div>
      <div className="loading-bar">
        <div className="loading-bar-progress"></div>
      </div>
    </div>
  );
};

export default Loading;