import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getAssetUrl } from './assetUtils';
import './Header.scss';

const Header = ({ project, projects, onProjectSelect, showBackToTimeline }) => {
  const logoUrl = getAssetUrl('logo.svg');
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  console.log('Raw projects:', projects);

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleBackToTimeline = () => {
    navigate('/about');
  };

  const handleProjectClick = (projectId) => {
    onProjectSelect(projectId);
    setIsMenuOpen(false);
    if (projectId === 'about') {
      navigate('/about');
    } else if (projectId === 'sustainability') {
      navigate('/sustainability');
    } else {
      navigate(`/gallery/${projectId}`);
    }
  };

  // Sort projects by order field
  const sortedProjects = projects ? [...projects]
    .filter(p => p.status === 'Public')
    .sort((a, b) => (a.order || 999) - (b.order || 999)) : [];

  console.log('Sorted and filtered projects:', sortedProjects);
  console.log('Current project:', project);

  const isAboutActive = location.pathname === '/about';
  const isSustainabilityActive = location.pathname === '/sustainability';

  return (
    <motion.header
      className="header"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 120, damping: 20 }}
    >
      <div className="header-logo-container">
        <img
          src={logoUrl}
          alt="Elodie Carstensen Logo"
          className="header-logo"
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        />
      </div>
      {showBackToTimeline ? (
        <button className="back-to-timeline" onClick={handleBackToTimeline}>
          Back to timeline
        </button>
      ) : (
          project && projects && projects.length > 0 && (
          <div
            className={`header-project-menu ${isMenuOpen ? 'open' : ''}`}
            onMouseEnter={() => setIsMenuOpen(true)}
            onMouseLeave={() => setIsMenuOpen(false)}
            role="navigation"
            aria-label="Project navigation"
          >
            <button
                className={`current-project ${(isAboutActive && project.id === 'contact') || (isSustainabilityActive && project.id === 'sustainability') ? 'active' : ''}`}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-expanded={isMenuOpen}
              aria-controls="project-menu"
              aria-label="Toggle project menu"
            >
              <span className="project-name">{project.name}</span>
              {project.season && (
                <>
                  <span className="project-separator">/</span>
                  <span className="project-season">{project.season}</span>
                </>
                )}
              <span className="menu-indicator" aria-hidden="true">
                  {sortedProjects.map((p) => (
                    <span
                      key={p.id}
                      className={`dot ${project.id === p.id ? 'active' : ''}`}
                    />
                  ))}
              </span>
            </button>
            <div
              id="project-menu"
              className={`project-options ${isMenuOpen ? 'open' : ''}`}
              role="menu"
            >
                {console.log('Projects to show in menu:', sortedProjects.filter(p => p.id !== project.id))}
              {sortedProjects
                .filter(p => p.id !== project.id)
                .map(p => (
                  <button
                    key={p.id}
                    className={`project-option ${(isAboutActive && p.id === 'contact') || (isSustainabilityActive && p.id === 'sustainability') ? 'active' : ''}`}
                    onClick={() => handleProjectClick(p.id)}
                    role="menuitem"
                  >
                    <span className="project-name">{p.name}</span>
                    {p.season && (
                      <>
                        <span className="project-separator">/</span>
                        <span className="project-season">{p.season}</span>
                      </>
                    )}
                  </button>
                ))}
            </div>
          </div>
          )
      )}
    </motion.header>
  );
};

export default Header;
