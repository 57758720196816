import projectsData from '../data/projects.json';
import aboutData from '../data/about.json';

export const loadProjects = async () => {
  const projects = await Promise.all(
    projectsData.map(async (project) => {
      try {
        // Special cases: always load data for about and sustainability regardless of status
        if (project.id === 'about' || project.id === 'sustainability') {
        // For 'about', we have a separate import
          if (project.id === 'about') {
            return { ...project, ...aboutData };
          }
          // For other special cases, load from their JSON files
          const module = await import(`../data/${project.id}.json`);
          return { ...project, ...module.default };
        }

        // Regular case: only load data for Public projects
        if (project.status === 'Public') {
          const module = await import(`../data/${project.id}.json`);
          return { ...project, ...module.default };
        }
        return project;
      } catch (error) {
        console.warn(`Failed to load project ${project.id}:`, error);
        return project;
      }
    })
  );
  return projects;
};

export const loadAbout = () => aboutData;
