import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// Default meta data that can be used across the site
export const defaultMetaData = {
  title: "Elodie Carstensen - Fashion Designer & Artist",
  description: "Berlin-based artist and designer creating immersive fashion experiences through collections, installations, and performances.",
  image: "https://www.elodiecarstensen.com/social-preview.jpg",
  url: "https://www.elodiecarstensen.com",
  type: "website",
  structuredData: {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Elodie Carstensen",
    "url": "https://www.elodiecarstensen.com",
    "jobTitle": "Fashion Designer & Artist",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Berlin",
      "addressCountry": "DE"
    },
    "description": "Berlin-based artist and designer creating immersive fashion experiences through collections, installations, and performances.",
    "image": "https://www.elodiecarstensen.com/social-preview.jpg",
    "sameAs": ["https://www.instagram.com/elodiecarstensen/"]
  }
};

const MetaTags = ({
  title = defaultMetaData.title,
  description = defaultMetaData.description,
  image = defaultMetaData.image,
  url = defaultMetaData.url,
  type = defaultMetaData.type,
  structuredData = defaultMetaData.structuredData,
  children
}) => {
  // Log the props being passed to MetaTags
  console.group('MetaTags Component Rendering');
  console.log('Title:', title);
  console.log('Description:', description);
  console.log('Image:', image);
  console.log('URL:', url);
  console.log('Type:', type);
  console.log('Structured Data:', structuredData);
  console.groupEnd();

  // Use useEffect to verify meta tags after they're set
  useEffect(() => {
    // Wait for next tick to check meta tags
    setTimeout(() => {
      console.group('MetaTags Verification');
      const metaTags = document.querySelectorAll('meta');
      console.log('Meta tags in document:', metaTags.length);
      console.log('og:title:', document.querySelector('meta[property="og:title"]')?.content);
      console.log('og:description:', document.querySelector('meta[property="og:description"]')?.content);
      console.log('og:image:', document.querySelector('meta[property="og:image"]')?.content);
      console.groupEnd();
    }, 0);
  }, [title, description, image]);

  return (
    <Helmet prioritizeSeoTags>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} data-rh="true" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} data-rh="true" />
      <meta property="og:url" content={url} data-rh="true" />
      <meta property="og:title" content={title} data-rh="true" />
      <meta property="og:description" content={description} data-rh="true" />
      <meta property="og:image" content={image} data-rh="true" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" data-rh="true" />
      <meta property="twitter:domain" content="elodiecarstensen.com" data-rh="true" />
      <meta property="twitter:url" content={url} data-rh="true" />
      <meta name="twitter:title" content={title} data-rh="true" />
      <meta name="twitter:description" content={description} data-rh="true" />
      <meta name="twitter:image" content={image} data-rh="true" />

      {/* Structured Data */}
      <script type="application/ld+json" data-rh="true">
        {JSON.stringify(structuredData)}
      </script>

      {/* Allow for additional custom meta tags */}
      {children}
    </Helmet>
  );
};

// Helper functions for specific page types
export const getProjectMetaData = (project) => {
  if (!project) {
    console.log('getProjectMetaData: No project provided, returning default meta data');
    return defaultMetaData;
  }

  console.log('getProjectMetaData: Generating meta data for project:', {
    id: project.id,
    name: project.name,
    season: project.season,
    hasDescription: !!project.description,
    hasAboutText: !!project.images?.[0]?.text,
    firstImageSrc: project.images?.[0]?.src
  });

  const description = project.description || project.images?.[0]?.text ||
    `${project.name} - A fashion collection by Elodie Carstensen`;

  const imageUrl = project.images?.[0]?.src ?
    `https://www.elodiecarstensen.com/assets/images/${project.images[0].src}` :
    defaultMetaData.image;

  console.log('getProjectMetaData: Using values:', {
    description,
    imageUrl
  });

  return {
    title: `${project.name} by Elodie Carstensen - ${project.season}`,
    description,
    image: imageUrl,
    url: `https://www.elodiecarstensen.com/gallery/${project.id}`,
    type: "article",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "CreativeWork",
      "name": project.name,
      "creator": {
        "@type": "Person",
        "name": "Elodie Carstensen"
      },
      "description": description,
      "image": imageUrl,
      "datePublished": project.season
    }
  };
};

export const getEventMetaData = (event) => {
  if (!event) {
    console.log('getEventMetaData: No event provided, returning default meta data');
    return defaultMetaData;
  }

  console.log('getEventMetaData: Generating meta data for event:', {
    title: event.title,
    date: event.date,
    location: event.location,
    hasDescription: !!event.description,
    hasPreviewImages: !!event.preview_images?.length
  });

  const description = event.description || defaultMetaData.description;
  const imageUrl = event.preview_images?.[0] ?
    `https://www.elodiecarstensen.com/assets/images/${event.preview_images[0]}` :
    defaultMetaData.image;

  console.log('getEventMetaData: Using values:', {
    description,
    imageUrl
  });

  return {
    title: `${event.title} - Elodie Carstensen`,
    description,
    image: imageUrl,
    url: `https://www.elodiecarstensen.com/events/${event.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`,
    type: "article",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": event.title,
      "description": description,
      "startDate": event.date,
      "endDate": event.date,
      "location": {
        "@type": "Place",
        "name": event.location,
        "address": {
          "@type": "PostalAddress",
          "addressLocality": event.location
        }
      },
      "organizer": {
        "@type": "Person",
        "name": "Elodie Carstensen",
        "url": "https://www.elodiecarstensen.com"
      },
      "image": imageUrl
    }
  };
};

export const getAboutMetaData = () => {
  console.log('getAboutMetaData: Generating about page meta data');
  return {
    ...defaultMetaData,
    title: "About Elodie Carstensen - Fashion Designer & Artist",
    description: "Discover the world of Elodie Carstensen - Berlin-based fashion designer and artist creating immersive experiences through collections, installations, and performances.",
    url: "https://www.elodiecarstensen.com/about",
    type: "profile"
  };
};

export const getSustainabilityMetaData = () => {
  console.log('getSustainabilityMetaData: Generating sustainability page meta data');
  return {
    ...defaultMetaData,
    title: "Sustainability - Elodie Carstensen",
    description: "Learn about Elodie Carstensen's commitment to sustainable fashion practices, ethically sourced materials, and environmentally conscious design.",
    url: "https://www.elodiecarstensen.com/sustainability",
    type: "article"
  };
};

export default MetaTags;