const IMAGE_CACHE = new Map();
const IMAGE_BASE_PATH = '/assets/images';

// Helper to check if an image exists in the public folder
const checkImageExists = async (path) => {
  try {
    const response = await fetch(path, { method: 'HEAD' });
    return response.ok;
  } catch {
    return false;
  }
};

// Helper to normalize image path
const normalizeImagePath = (path) => {
  if (!path) return null;

  // If it's already a full URL, return as is
  if (path.startsWith('http://') || path.startsWith('https://')) {
    console.debug('Image is already a full URL:', path);
    return path;
  }

  // Remove any leading slashes and 'assets/images'
  const cleanPath = path.replace(/^\/?(assets\/images\/)?/, '');
  const normalizedPath = `${IMAGE_BASE_PATH}/${cleanPath}`;
  console.debug('Normalized local path:', normalizedPath);
  return normalizedPath;
};

// Helper to check if URL is from Airtable
const isAirtableUrl = (url) => {
  return url && (
    url.includes('amazonaws.com') ||
    url.includes('airtable.com') ||
    url.includes('dl.airtable')
  );
};

// Main function to get image URL with caching
export const getImageUrl = async (path) => {
  if (!path) return null;

  console.debug('Getting image URL for:', path);

  // Check cache first
  if (IMAGE_CACHE.has(path)) {
    console.debug('Found in cache:', IMAGE_CACHE.get(path));
    return IMAGE_CACHE.get(path);
  }

  // If it's an Airtable URL, use it directly
  if (isAirtableUrl(path)) {
    console.debug('Using Airtable URL directly:', path);
    IMAGE_CACHE.set(path, path);
    return path;
  }

  const normalizedPath = normalizeImagePath(path);

  // Check if image exists in public folder
  const exists = await checkImageExists(normalizedPath);

  if (exists) {
    console.debug('Found local image:', normalizedPath);
    IMAGE_CACHE.set(path, normalizedPath);
    return normalizedPath;
  }

  // If image doesn't exist locally and isn't an Airtable URL, return null
  console.debug('Image not found:', path);
  IMAGE_CACHE.set(path, null);
  return null;
};

// Synchronous version for cases where we can't use async
export const getImageUrlSync = (path) => {
  if (!path) return null;

  console.debug('Getting image URL sync for:', path);

  // Check cache first
  if (IMAGE_CACHE.has(path)) {
    console.debug('Found in cache:', IMAGE_CACHE.get(path));
    return IMAGE_CACHE.get(path);
  }

  // If it's an Airtable URL, use it directly
  if (isAirtableUrl(path)) {
    console.debug('Using Airtable URL directly:', path);
    IMAGE_CACHE.set(path, path);
    return path;
  }

  // For sync calls, just normalize and return the path
  const normalizedPath = normalizeImagePath(path);
  IMAGE_CACHE.set(path, normalizedPath);
  return normalizedPath;
};