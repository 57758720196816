import React, { useState } from 'react'
import { getImageUrl } from './utils/imageUtils'
import './SustainabilityPage.scss'
import MetaTags from './components/MetaTags'

const ImageGallery = ({ images }) => {
  const [imageUrls, setImageUrls] = useState({})

  React.useEffect(() => {
    const loadImages = async () => {
      const urls = {}
      for (const image of images) {
        urls[image.path] = await getImageUrl(image.path)
      }
      setImageUrls(urls)
    }
    if (images?.length) {
      loadImages()
    }
  }, [images])

  if (!images?.length) return null

  return (
    <div className="image-gallery">
      {images.map((image, index) => (
        <div key={index} className="gallery-item">
          {imageUrls[image.path] && (
            <>
              <img src={imageUrls[image.path]} alt={image.alt} />
              {image.caption && <div className="image-caption">{image.caption}</div>}
            </>
          )}
        </div>
      ))}
    </div>
  )
}

const SubSection = ({ subsection }) => {
  return (
    <div className="subsection">
      <div className="subsection-content">
        <h3 className="subsection-title">{subsection.title}</h3>
        <p className="subsection-text">{subsection.text}</p>
        {subsection.source && (
          <div className="source">
            <span className="source-label">Source:</span> {subsection.source}
          </div>
        )}
      </div>
      {subsection.images && <ImageGallery images={subsection.images} />}
    </div>
  )
}

const Section = ({ section }) => {
  return (
    <section className="section">
      <h2 className="section-title">{section.title}</h2>
      {section.text && <p className="section-text">{section.text}</p>}
      <div className="subsections">
        {section.subsections.map((subsection, index) => (
          <SubSection key={index} subsection={subsection} />
        ))}
      </div>
    </section>
  )
}

const SustainabilityPage = ({ data }) => {
  // Early return if no data is provided
  if (!data) {
    return <div>Loading...</div>
  }

  return (
    <>
      <MetaTags
        title="Sustainability - Elodie Carstensen"
        description="Learn about Elodie Carstensen's commitment to sustainable fashion practices, ethically sourced materials, and environmentally conscious design."
        url="https://www.elodiecarstensen.com/sustainability"
        type="article"
      />
      <div className="sustainability-page">
        <div className="sustainability-content">
          <div className="sustainability-frame">
            <h1>{'// Sustainability'}</h1>

            {data.sections && Object.values(data.sections).map((section, index) => (
              <Section key={index} section={section} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default SustainabilityPage