import projects from '../data/projects.json';

// Get project details by ID (useful for routing)
export const getProjectById = (projectId) => {
  return projects.find(p => p.id === projectId);
};

// Get project by Airtable ID (useful for cross-references)
export const getProjectByAirtableId = (airtableId) => {
  return projects.find(p => p.airtableId === airtableId);
};

// Format a compact reference to a project
export const formatProjectReference = (airtableId) => {
  const project = getProjectByAirtableId(airtableId);
  return project?.abbreviation || airtableId;
};